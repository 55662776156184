import { useEffect, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Wrapper } from "@googlemaps/react-wrapper";
// import { GMAPS_API_KEY } from "utils/constants";
import ChargerBookedIcon from "assets/images/charger-booked.svg";
import ChargerBookedHoverIcon from "assets/images/charger-booked-hover.svg";
import ChargerAvailableIcon from "assets/images/charger-available.svg";
import ChargerAvailableHoverIcon from "assets/images/charger-available-hover.svg";
import ChargerPinIcon from "assets/images/charger-pin.svg";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState, validateLatLng } from "utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useHistory } from "react-router-dom";
import ChargerHiddenIcon from "assets/images/charger-hidden.svg";
import ChargerHiddenHoverIcon from "assets/images/charger-hidden-hover.svg";
import VehiclePinIcon from "assets/images/bike-pin.svg";
import HubPinIcon from "assets/images/hub-pin.svg";

interface MapProps {
  loading: boolean;
  type: "charger" | "points" | "heatmap" | "cluster" | "hub";
  showVehicleIcon?: boolean;
  borderRadius?: number | string;
  dataArray?: any;
  location?: any;
  onClick?: (args: any) => void;
  showControls?: boolean;
  inViewref?: any;
}

const MapComponent: React.FC<MapProps> = ({ borderRadius, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 1,
        height: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: borderRadius ? borderRadius : 0,
        overflow: "hidden",
      }}
      ref={props.inViewref}
    >
      {props.loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Wrapper
          libraries={["visualization", "places", "drawing", "geometry"]}
          apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
        >
          <GoogleMap {...props} />
        </Wrapper>
      )}
    </Box>
  );
};

const GoogleMap: React.FC<Partial<MapProps>> = ({
  type,
  dataArray,
  location,
  onClick,
  showVehicleIcon,
  showControls,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );
  const history = useHistory();
  const ref = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    setMap(
      new window.google.maps.Map(
        ref.current as HTMLElement,
        {
          mapId: isDarkMode ? "1c049bffd0581e1f" : "5c866e462475c128",
          center:
            type === "charger" && location
              ? { lat: location.latitude, lng: location.longitude }
              : type === "hub" && location
                ? { lat: location.latitude, lng: location.longitude }
                : { lat: 12.9716, lng: 77.5946 },
          zoom: type === "charger" ? 15 : 12,
          streetViewControl: false,
          zoomControl: showControls,
          minZoom: 2,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControl: showControls,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        } as google.maps.MapOptions,
      ),
    );
  }, [type, location, isDarkMode, showControls]);

  useEffect(() => {
    if (!map) return;
    if (type === "points" || type === "charger") {
      if (dataArray) {
        let bounds = new google.maps.LatLngBounds();

        let array = (dataArray || []).filter((el: any) => {
          let { latitude, longitude } = el?.station?.location || {};
          return type === "charger"
            ? !(
                latitude === location.latitude &&
                longitude === location.longitude
              )
            : validateLatLng(latitude, longitude);
        });

        const markers = array.map((charger: any) => {
          let chargerId = charger.id || charger.charger.chargerId;
          let status = charger?.charger?.chargerStatus || "";
          let hidden = charger?.charger?.hidden || "";
          let position = {
            lat: Number(charger.station.location.latitude),
            lng: Number(charger.station.location.longitude),
          };
          let anchor = showVehicleIcon
            ? new google.maps.Point(20, 48)
            : new google.maps.Point(11, 11);

          let defaultIcon = {
            url: showVehicleIcon
              ? VehiclePinIcon
              : hidden === true
                ? ChargerHiddenIcon
                : status === "BOOKED"
                  ? ChargerBookedIcon
                  : ChargerAvailableIcon,
            anchor,
          };
          let hoveredIcon = {
            url: showVehicleIcon
              ? VehiclePinIcon
              : hidden === true
                ? ChargerHiddenHoverIcon
                : status === "BOOKED"
                  ? ChargerBookedHoverIcon
                  : ChargerAvailableHoverIcon,
            anchor,
          };

          const marker = new google.maps.Marker({
            position,
            map,
            icon: defaultIcon,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: chargerId,
            position,
          });

          if (!showVehicleIcon)
            marker.addListener("click", () => {
              if (onClick) onClick(chargerId);
              else history.push("/chargers?id=" + chargerId);
            });
          if (showVehicleIcon)
            marker.addListener("click", () => {
              history.push("/vehicles?vin=" + chargerId);
            });
          marker.addListener("mouseover", () => {
            marker.setIcon(hoveredIcon);
            infoWindow.open(marker.get("map"), marker);
          });
          marker.addListener("mouseout", () => {
            marker.setIcon(defaultIcon);
            infoWindow.close();
          });

          let latLng = new google.maps.LatLng(position.lat, position.lng);
          bounds.extend(latLng);

          return marker;
        });
        new MarkerClusterer({ map, markers });
        if (array.length > 0) map?.fitBounds(bounds);
      }
    }

    if (type === "charger" || type === "hub") {
      new google.maps.Marker({
        position: {
          lat: location?.latitude || 12.99797722402445,
          lng: location?.longitude || 77.63656987220915,
        },
        map,
        icon: {
          url: type === "charger" ? ChargerPinIcon : HubPinIcon,
          anchor: new google.maps.Point(29, 58),
        },
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [map, isDarkMode, type, dataArray, location]);

  return (
    <Box
      ref={ref}
      sx={{ position: "absolute", width: 1, height: 1, color: "black" }}
    />
  );
};

export default MapComponent;
